export const environment = {
  localeId: 'en-GB',
  production: true,
  apiUrl: 'https://uk-e2e.tim-test.talonoutdoor.com/trading-system/api/inventory-management/',
  productServiceApiUrl: 'https://uk-e2e.tim-test.talonoutdoor.com/trading-system/api/product-service/',
  accountServiceApiUrl: 'https://uk-e2e.tim-test.talonoutdoor.com/trading-system/api/account-service/',
  organizationServiceApiUrl: 'https://uk-e2e.tim-test.talonoutdoor.com/trading-system/api/organization-service/',
  poiUrl: 'https://uk-e2e.tim-test.talonoutdoor.com/api/poi-service/',
  endPoints: {
    frames: {
      search: 'frames/search',
      create: 'v1/frame',
      delete: 'v1/frame/',
      availability: 'frames/avails',
    },
    locale: 'tenantconfig',
    timezone: 'timezones',
    location: 'v1/locations',
    filters: {
      environment: 'frames/attributes/values/distinct?page=0&size=2000&name=environment.name',
      format: 'frames/attributes/values/distinct?page=0&size=2000&name=format.formatName',
      state: 'frames/attributes/values/distinct?page=0&size=2000&name=geography.state',
      network: 'frames/distinct/networks?page=0&size=100&mediaOwnerName=',
    },
    frame: {
      byFrameId: 'v1/frame/',
      photoUrls: 'frames/',
    },
    markPhotoAsPrimary: 'frames/markPhotoAsPrimary',
    poi: {
      search: 'poi/v3/search?page=0&size=10000',
      ClassificationCode: 'poi/classifier/distinct/classifier?page=0&size=500',
      brands: 'poi/distinct/brands?page=0&size=1000',
      businessNames: 'poi/distinct/businessNames?page=0&size=20',
    },
    trading: {
      products: 'products',
      organization: 'organizations',
      accounts: 'accounts',
      categoryProhibitionsMapping: 'brand/category',
    },
    order: {
      report: 'orders/report',
    },
  },
  fileUploadURL: 'v1/frame/bulkupload',
  bulkUploadJobStatus: 'v1/frame/bulkupload/job/',
  errorFileDownload: 'v1/frame/bulkupload/job/file?url=',
  bulkUploadStatusList: 'v1/frame/bulkupload/',
  photosColumnMapping: 'v1/frame/photosColumnMapping',

  auth0: {
    clientId: '0i5mM49ozLznjjTRVuxfyZsJCssq9WvN',
    domain: 'auth-test.plato2.talonoutdoor.com',
    callbackUrl: 'https://uk-e2e.tim-test.talonoutdoor.com/login',
    audience: 'https://timuk.talonoutdoor.com/api/',
    appUrl: 'https://timuk.talonoutdoor.com',
  },

  downloadInventoryTemplateEndpoint: 'v1/getInventoryTemplate',
  downloadPricingRulesTemplateEndpoint: 'pricing/template',
  pricingUploads: 'pricing/uploads',
  downloadUploadedPricingTemplates: 'pricing/downloadPricing',
  markPhotoAsPrimary: 'frames/markPhotoAsPrimary',
  maximumNumberOfFilesCanBeUploaded: 10,
  maximumLeadTimeCanBeGiven: 10,
  maximumNumberOfFramePhotos: 10,
  maximumPhotoFileSizeInMB: 5,
  redirectionTimeFromCreateFrameToViewFrame: 4000,
  mapboxConstants: {
    apiKey: 'pk.eyJ1IjoidGFsb25vZG1wIiwiYSI6ImNqb2U2bWZlcDFsdHEzb253b2Vodnd3amUifQ.8ST92gdUUWdQROf8d7mHpA',
    styleForTiles: 'mapbox://styles/talonodmp/cjvxd5f925v6g1cs8f4cvxrwd',
  },
};
